import React from 'react';

const Updates = () => {
  return (
    <iframe
      src="https://v2-embednotion.com/Updates-f79f122fa8bb4c6d80d82b4ebbb7e00a"
      title="Updates"
      style={{
        width: '100%',
        height: '600px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        padding: 'none',
        boxShadow: '0 2px 2px #ccc '
      }}
    />
  );
};

export default Updates;
