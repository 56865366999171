import { lookupsJson, paymentMethod } from '@ourbranch/lookups';
import getIn from 'lodash-es/get';

const states = lookupsJson.usStates;

const mortgageFieldsNameMap = {
  loanNumber: 'Loan number',
  mortgageHolderName: 'Mortgage lender name',
  'mortgageHolderAddress.address': 'Address',
  'mortgageHolderAddress.city': 'City',
  'mortgageHolderAddress.zip': 'Zip code',
  'mortgageHolderAddress.state': 'State'
};

export function validUSState(name) {
  return this.test({
    name: `${name}.validUSState`,
    message: 'Please enter a valid US state',
    test: (val) => !val || states.some((state) => state.id === val),
    exclusive: false
  });
}

export function requiredString(label) {
  return this.ensure().required(label || 'Required');
}

// @precondition, needs to have affinityLookups store object in context
export function validAffinityCode(name, ctx) {
  const { affinityLookups, isAgency } = ctx;
  return this.test({
    name: `${name}.validAffinityCode`,
    message: 'Please enter a valid affinity partner name or code',
    test: function test(val) {
      if (val) {
        const matchedToAffinityInLookups = affinityLookups.data.find(({ affinity }) => affinity === val);

        if (!matchedToAffinityInLookups) {
          return this.createError({
            message: `Affinity value ${val} not found in our database. ${
              isAgency ? 'Please contact your designated agency success manager to fix the error to continue' : ''
            }`,
            path: name
          });
        }
      }
      return true;
    }
  });
}

export function validMortgageDetails(fieldName) {
  // The test passes if the payment method is not mortgage or if there's a value filled for mortgage holder name..
  // ...but we only need to check this in cases where the selected policy has a Home
  const normalizedFieldName = mortgageFieldsNameMap[fieldName];
  return this.test(
    'Require mortgage fields when home is added to policy',
    `${normalizedFieldName} is required`,
    function test() {
      const policyHasHome = this.options.context?.selectedOption?.includes('H');
      const isHomeMortgaged = this.options.context?.global?.homeownersPaymentMethod === paymentMethod.Escrow;
      const isFieldDirty = !!getIn(this.options.context?.primaryMortgageDetail, fieldName)?.trim()?.length;

      if (policyHasHome) return !isHomeMortgaged || isFieldDirty;

      return true;
    }
  );
}
