import React from 'react';
import { Tabs } from '@material-ui/core';

import { HeaderTab } from 'core/components/header-tab';
import portal from 'core/assets/svg/portal.svg';
import contact from 'core/assets/svg/contact.svg';
import pieChart from 'core/assets/svg/pie-chart.svg';
import pen from 'core/assets/svg/pen.svg';
import tool from 'core/assets/svg/tool.svg';
import puzzlePiece from 'core/assets/svg/puzzle-piece.svg';
import announcement from 'core/assets/svg/announcement.svg';
import claim from 'core/assets/svg/claim.svg';
import useStyles from './agency-tabs.styles';

const AgencyTabs = ({ activeTab, handleTabChange }) => {
  const classes = useStyles();

  return (
    <Tabs value={activeTab} onChange={handleTabChange} className={classes.wrapper}>
      <HeaderTab className={classes.tab} label="Updates" iconSrc={portal} iconSize="small" />
      <HeaderTab className={classes.tab} label="Reports & Commissions" iconSrc={pieChart} iconSize="small" />
      <HeaderTab className={classes.tab} label="Underwriting" iconSrc={pen} iconSize="small" />
      <HeaderTab className={classes.tab} label="Tools" iconSrc={tool} iconSize="small" />
      <HeaderTab className={classes.tab} label="Training" iconSrc={puzzlePiece} iconSize="small" />
      <HeaderTab className={classes.tab} label="Marketing" iconSrc={announcement} iconSize="small" />
      <HeaderTab className={classes.tab} label="Claims" iconSrc={claim} iconSize="small" />
      <HeaderTab className={classes.tab} label="Contact Us" iconSrc={contact} iconSize="small" />
    </Tabs>
  );
};

export default AgencyTabs;
