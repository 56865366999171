import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    paddingBottom: theme.spacing(1)
  },
  agentLabelWrappers: {
    height: 25,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  agentLabels: {
    textTransform: 'uppercase',
    fontSize: 12,
    margin: 'auto 0 auto 10px'
  },
  agentInfo: {
    fontSize: 12,
    margin: 'auto 0 auto 10px',
    whiteSpace: 'nowrap'
  },
  agentInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4)
  }
}));

export default useStyles;
